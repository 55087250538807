/* Built In Imports */
import { useContext } from 'react';

/* External Imports */
import { Box, Link, Text } from '@chakra-ui/layout';
import { nanoid } from 'nanoid';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import { AuthContext } from '@store/auth-context';

/* Services */
/* Styles */
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/**
 * Renders the Meet Sadhguru component
 *
 * @param {object} meetData -  meet sadhguru data
 * @param {string} myRef - ref
 * @returns {ReactElement} Meet Sdhguru component
 */

const MeetSadhguru = ({ SecContent, myRef }) => {
  const meetContent = SecContent;
  const meetData = meetContent?.meet_sadhguru;
  const authCtx = useContext(AuthContext);

  const NextArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        top="50%"
        zIndex="10"
        cursor="pointer"
        bgSize="10px"
        bgColor="#fff"
        border="0"
        borderRadius="100%"
        w="40px"
        h="40px"
        boxShadow="0 0 10px rgba(0, 0, 0, 0.5)"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        bgImage={`url(${config.staticPath}/assets/images/right-arrow.svg)`}
        right="-10px"
        onClick={onClick}
      ></Box>
    );
  };

  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        top="50%"
        zIndex="10"
        cursor="pointer"
        bgSize="10px"
        bgColor="#fff"
        border="0"
        borderRadius="100%"
        w="40px"
        h="40px"
        boxShadow="0 0 10px rgba(0, 0, 0, 0.5)"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        bgImage={`url(${config.staticPath}/assets/images/left-arrow.svg)`}
        left="-10px"
        // className={`${classes["meetSlick-arrow"]} ${classes.meetPrevArrow}`}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    centerMode: false,
    infinite: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    customPaging: function () {
      return (
        <Text
          cursor="pointer"
          display="inline-block"
          w="10px"
          h="10px"
          m="0px 8px"
          bgColor="#cf4520"
          borderRadius="50%"
        // className="bullet"
        ></Text>
      );
    },
    // dotsClass: "slick-dots meet-dots",
  };

  const items = [];
  // if (meetData) {
  //   meetData.map((meetSadhguru, i) => {
  //     items.push({
  //       icon: meetSadhguru[i + 1].icon,
  //       title: meetSadhguru[i + 1].title,
  //       date: meetSadhguru[i + 1].date,
  //       program_url: meetSadhguru[i + 1].program_url,
  //     });
  //   });
  // }

  return (
    <Box
      ref={myRef}
      float="left"
      w="100%"
      p={{
        base: '65px 0px 10px 0px',
        sm: '120px 0px 10px 0px',
        md: '80px 20px 10px 20px',
        lg: '80px 25px 10px 20px',
      }}
      bgColor="#ffffff"
      clipPath="polygon(100% 100%, 0% 100%, 0% 10%, 100% 0%)"
    >
      <Text
        fontFamily='"FedraSerifAStdBook",sans-serif'
        fontSize={{ base: '30px', sm: '30px', md: '45px' }}
        color="#aea493"
        pl="22px"
      >
        {meetContent?.title}
      </Text>
      <Box
        maxW="100%"
        m="0 auto"
        w="100%"
        overflow="hidden"
        // mt={{ base: '15px', sm: '15px', md: '15px' }}
        p="0px 15px 30px 15px"
      >
        <Slider {...settings} m="0">
          {meetData.results.map((data, i) => {
            console.log("data", data)
            let colorClass = '';
            let isEven = false;
            if (i % 3 === 0) {
              colorClass = '#f5ac3e';
            } else {
              if (i % 2 === 0) {
                colorClass = '#733e1e';
                isEven = true;
              } else {
                colorClass = '#004865';
              }
            }
            return (
              <Box overflow="hidden" p="10px 10px 30px 10px" key={nanoid()}>
                <Box>
                  <LazyLoadImageComponent
                    alt={data[i + 1].title}
                    height="225px"
                    title={data[i + 1].title}
                    width="100%"
                    src={data[i + 1].icon}
                  />
                </Box>
                <Box
                  w="100%"
                  float="left"
                  boxShadow="0 0 16px 0 rgb(0 0 0 / 15%)"
                >
                  <Box
                    clipPath={
                      isEven
                        ? 'polygon(0 0, 100% 25%, 100% 100%, 0% 100%)'
                        : 'polygon(0 25%, 100% 0, 100% 100%, 0% 100%)'
                    }
                    mt="-50px"
                    pl="25px"
                    pr="25px"
                    textAlign="left"
                    fontFamily='"FedraSerifAStdBook",sans-serif'
                    w="calc(100% + 1px)"
                    h="115px"
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    bgColor={colorClass}
                    fontSize={{ base: '18px', sm: '18px', md: '22px' }}
                    color="#fff"
                    lineHeight="1.18"
                    pt="12px"
                  >
                    {data[i + 1].title}
                  </Box>
                  <Box>
                    <Box p="25px">
                      <Text
                        color="#000"
                        fontSize="20px"
                        textAlign="left"
                        lineHeight="1.15"
                      >
                        {data[i + 1].date}
                      </Text>
                      <Text
                        color="#676055"
                        fontSize="16px"
                        lineHeight="1.25"
                        mt="5px"
                        textAlign="left"
                        minH="25px"
                      >
                        {data[i + 1].address}
                      </Text>
                    </Box>
                    <Box p="25" float="left" w="100%">
                      <Link
                        float="left"
                        w="125px"
                        h="30px"
                        fontFamily="'FedraSansStd-medium', sans-serif"
                        fontSize="13px"
                        textAlign="center"
                        lineHeight="31px"
                        color="#fff"
                        borderRadius="3px"
                        bgColor="#cf4520"
                        href={data[i + 1].program_url}
                        // href={refineUrlDomain(data[i + 1].program_url, authCtx.region, authCtx.lang)}
                        textDecoration="none"
                        _hover={{ textDecoration: 'none' }}
                      >
                        {data[i + 1]?.button_label || 'Register Now'}
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Slider>
      </Box>
      <style jsx global>{`
          .slick-dots li {
            opacity: 0.25;
          }
          .slick-dots li.slick-active {
            opacity: 1;
          }
          .slick-track {
            margin: 0 !important;
          }
        `}</style>
    </Box>
  );
};

export default MeetSadhguru;
